import { useForm } from "react-hook-form";
import React, { createContext, createRef, useState } from "react";

export const GenerationContext = createContext();

export const GenerationProvider = ({ children }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [generationPrompt, setGenerationPrompt] = useState("");
    const [generationStillPending, setGenerationStillPending] = useState(false);
    const [imageCount, setImageCount] = useState(4);
    const promptInputRef = createRef();

    const handleSetGenerationPrompt = (value) => {
        setValue("generationPrompt", value);
        setGenerationPrompt(value);
    };

    return (
        <GenerationContext.Provider
            value={{
                register,
                handleSubmit,
                setValue,
                watch,
                errors,
                generationPrompt,
                handleSetGenerationPrompt,
                generationStillPending,
                setGenerationStillPending,
                imageCount,
                setImageCount,
                promptInputRef,
            }}
        >
            {children}
        </GenerationContext.Provider>
    );
};
