import React, { useEffect, useState } from "react";
import GenerationStatusCard from "./GenerationStatusCard";
import { matchPath, useLocation, useHistory } from "react-router-dom";
import { GENERATION_STATUS } from "@common/constants/constants";
import Icon from "../Icon";
import {
    checkGenerationStatus,
    removeAllPendingGenerations,
    removePendingGenerationById,
} from "@common/redux/actions/generationActions";
import { useDispatch, useSelector } from "react-redux";
import { getTotalUsage } from "@common/redux/actions/paymentActions";

const GenerationStatusBar = () => {
    const [currentTab, setCurrentTab] = useState("explore");
    const [isVisible, setIsVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [{ organization }, { generationPageDetails, pendingGenerations }] = useSelector(
        (state) => [state.organizationDetails, state.generationDetails],
    );
    const location = useLocation();
    const match = matchPath(location.pathname, {
        path: "/organization/:orgId/dashboard/:tab",
        exact: true,
        strict: false,
    });

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentTab(match?.params?.tab);
        if (match?.params?.tab === "my-generations") setIsExpanded(false);
    }, [location]);

    useEffect(() => {
        setIsVisible(currentTab !== "my-generations" && pendingGenerations.length > 0);
    }, [currentTab, pendingGenerations]);

    return (
        <div
            className={`fixed w-screen h-0 flex justify-center transition-status-bar duration-300 ease-in-out ${
                isVisible ? "bottom-[161px] md:bottom-12 opacity-100" : "-bottom-28 opacity-0"
            }`}
        >
            {/* status bar bg */}
            <>
                <div
                    className={`absolute flex flex-col-reverse gap-1 min-h-17 max-h-[347px] z-40 rounded-12 transition-status-wrapper duration-300 ease-in-out bottom-0 ${
                        pendingGenerations.length > 1
                            ? isExpanded
                                ? "m-0 w-[358px] md:w-[423px] bg-grey-800 h-max p-2.5 pt-[53px]"
                                : "m-0 w-[339px] md:w-[379px] bg-grey-700"
                            : "w-[338px] md:w-[403px]"
                    }`}
                >
                    <div
                        className={`absolute top-4 left-5 right-5 flex flex-row justify-between${
                            isVisible ? "" : "opacity-0 hidden"
                        }`}
                    >
                        <div className="font-semibold text-lg leading-19 text-grey-white">
                            My Generations ({pendingGenerations.length})
                        </div>
                        <button
                            className="font-normal text-base leading-21 text-grey-200"
                            onClick={() => {
                                setIsVisible(false);
                                setIsExpanded(false);
                                removeAllPendingGenerations(dispatch);
                            }}
                        >
                            Clear all
                        </button>
                    </div>

                    {/* Status bar collapse button */}
                    <div
                        className={`absolute inset-x-0 m-auto w-[89px] flex justify-center z-30 transition-status-expand duration-300 ease-in-out ${
                            pendingGenerations.length > 1
                                ? isExpanded
                                    ? "-top-14 opacity-100"
                                    : "top-4 opacity-0"
                                : "top-2 opacity-0"
                        }`}
                    >
                        <button
                            className="text-center w-11 h-11 bg-grey-600 hover:bg-grey-700 p-2 rounded-full"
                            onClick={() => setIsExpanded(false)}
                        >
                            <Icon type="chevronDown" className={{ wrapper: "fill-grey-white" }} />
                        </button>
                    </div>

                    {/* Status bar expand button */}
                    <div
                        className={`absolute inset-x-0 m-auto w-[89px] flex justify-center z-30 transition-status-expand duration-300 ease-in-out ${
                            pendingGenerations.length > 1
                                ? isExpanded
                                    ? "top-16 opacity-0"
                                    : "-top-4 opacity-100"
                                : "top-2 opacity-0"
                        }`}
                    >
                        <button
                            className="text-center rounded-t-8 bg-grey-700 hover:bg-grey-600 border-t border-l border-r px-2 py-1 border-grey-600 text-base font-normal text-nowrap"
                            onClick={() => setIsExpanded(true)}
                        >
                            +{pendingGenerations.length - 1} more
                        </button>
                    </div>

                    {/* pending generations list wrapper */}
                    <div
                        className={`rounded-12 md:w-[403px] flex flex-col-reverse gap-1 h-fit min-h-17 max-h-[284px] transition-status-list duration-300 ease-in-out overflow-y-scroll z-40 ${
                            pendingGenerations.length > 1
                                ? isExpanded
                                    ? "w-[338px]"
                                    : "w-[360px] mt-3 -mx-3"
                                : ""
                        }`}
                    >
                        {pendingGenerations.map((generation, idx) => {
                            return (
                                <GenerationStatusCard
                                    key={idx}
                                    generation={generation}
                                    isExpanded={isExpanded}
                                    onClick={{
                                        notPolling: () => {
                                            checkGenerationStatus(
                                                organization?._id,
                                                generation._id,
                                                generation.generationId,
                                                dispatch,
                                            ).then(() => {
                                                getTotalUsage(organization?._id, dispatch);
                                            });
                                        },
                                        polling: () => {
                                            history.push(
                                                `/organization/${organization?._id}/dashboard/my-generations`,
                                            );
                                        },
                                        success: () => {
                                            history.push(
                                                `/organization/${organization?._id}/generations/${generation._id}`,
                                            );
                                            removePendingGenerationById(generation._id, dispatch);
                                        },
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </>
        </div>
    );
};

export default GenerationStatusBar;
