import BgDecorator from "@common/components/BgDecorator";
import { GenerationProvider } from "@components/GenerationForm/GenerationContext";
import Navbar from "@components/GlobalNavBar";
import PrivateRoute from "@components/PrivateRoute";
import ShimmerLoop from "@public/videos/ShimmerLoop.webm";
import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";
import GenerationStatusBar from "@common/components/GenerationStatusBar/index";
import BrandLoaderIcon from "@common/components/BrandLoaderIcon";

const App = () => {
    const [authDetails] = useSelector((state) => [state.authenticationDetails]);
    const [{ organization }, { isLoaderOverlayVisible }] = useSelector((state) => [
        state.organizationDetails,
        state.menuDetails,
    ]);
    return (
        <BrowserRouter>
            <Suspense fallback={<></>}>
                <GenerationProvider>
                    <Navbar user={authDetails.user} organization={organization} />
                    <div className="w-screen h-[calc(100vh_-_162px)]">
                        <Switch>
                            {routes.map((route, index) => (
                                <Route path={route.path} key={index}>
                                    <PrivateRoute
                                        component={route.component}
                                        guard={route.guard}
                                        path={route.path}
                                    />
                                </Route>
                            ))}
                        </Switch>
                        {authDetails.user && organization && (
                            <>
                                <BgDecorator />
                                <GenerationStatusBar />
                            </>
                        )}
                    </div>
                    {isLoaderOverlayVisible && (
                        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                            <BrandLoaderIcon className="h-20 w-20" />
                        </div>
                    )}
                </GenerationProvider>
            </Suspense>
        </BrowserRouter>
    );
};

export default hot(module)(App);
