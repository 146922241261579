import React from "react";
import ReactDOM from "react-dom";
import store from "./common/redux/store";
import App from "./App";
import { Provider } from "react-redux";
import "./common/events";
import "./index.css";
import { Toaster } from "sonner";
import Icon from "@components/Icon";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
            <Toaster
                visibleToasts={5}
                position="top-center"
                expand={true}
                icons={{
                    success: <Icon type="altTick" className={{ wrapper: "fill-white" }} />,
                    error: <Icon type="error" className={{ wrapper: "fill-white" }} />,
                }}
                toastOptions={{
                    unstyled: true,
                    classNames: {
                        success:
                            "bg-success-dark rounded-4 flex flex-row-reverse justify-center items-center gap-[10px] px-2 py-1 select-none",
                        error: "bg-error-dark rounded-4 flex flex-row-reverse justify-center items-center gap-[10px] px-2 py-1 select-none",
                    },
                }}
                className="flex justify-center font-body text-base font-normal select-none"
                gap={8}
            />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root"),
);
