export const FCScriptInitiated = "SCRIPT_INITIATED";
export const AuthorisedUser = "AUTHORISED_USER";
export const UnAuthorisedUser = "UNAUTHORISED_USER";
export const SetFcRestoredId = "SET_FC_RESTORED_ID";
export const widgetLoaded = "WIDGET_LOADED";
export const NavItemClick = "NAV_ITEM_CLICK";
export const UserSignUpAttempt = "USER_SIGN_UP_ATTEMPT";
export const UserLoginAttempt = "USER_LOGIN_ATTEMPT";
export const UserSignUpSuccess = "USER_SIGN_UP_SUCCESS";
export const UserLoginSuccess = "USER_LOGIN_SUCCESS";
export const UserSignUpFailed = "USER_SIGN_UP_FAILED";
export const UserLoginFailed = "USER_LOGIN_FAILED";
export const ValidationError = "VALIDATION_ERROR";
export const EmailVerificationSuccess = "EMAIL_VERIFICATION_SUCCESS";
export const EmailVerificationFailed = "EMAIL_VERIFICATION_FAILED";
export const EmailVerificationContinue = "EMAIL_VERIFICATION_CONTINUE";
export const SetupOrganization = "SETUP_ORGANISATION";
export const SettingSidebarItemsClick = "SETTING_SIDEBAR_ITEMS_CLICK";
export const TransformationClick = "TRANSFORMATION_CLICK";
export const ApplyTransformationClick = "APPLY_TRANSFORMATION_CLICK";
export const ChangeImageAttempt = "CHANGE_IMAGE_ATTEMPT";
export const ChangeImageSuccess = "CHANGE_IMAGE_SUCCESS";
// export const ChangeImageFailed = "CHANGE_IMAGE_FAILED"; // to be done later
export const DownloadImageClick = "DOWNLOAD_IMAGE_CLICK";
export const CopyUrlClick = "COPY_URL_CLICK";
export const SaveAsPresetSuccess = "SAVE_AS_PRESET_SUCCESS";
export const SaveAsPresetFailed = "SAVE_AS_PRESET_FAILED";
export const SegmentScriptInitiated = "SEGMENT_SCRIPT_INITIATED";

// DASHBOARD EVENTS
export const DASHBOARD_CAROUSEL_CLICK = "DASHBOARD_CAROUSEL_CLICK";
export const DASHBOARD_PLAN_UPGRADE = "DASHBOARD_PLAN_UPGRADE";
export const DASHBOARD_CONNECT_SDK = "DASHBOARD_CONNECT_SDK";
export const DASHBOARD_CONNECT_EXTERNAL_STORAGE = "DASHBOARD_CONNECT_EXTERNAL_STORAGE";
export const DASHBOARD_READ_DOCUMENTATION = "DASHBOARD_READ_DOCUMENTATION";
export const ReportIssue = "REPORT_ISSUE";
export const ReportIssueClosed = "REPORT_ISSUE_CLOSED";

// UPLOAD EVENTS
export const CancelAllUpload = "CANCEL_ALL_UPLOAD";
export const TryAgainAllUpload = "TRY_AGAIN_ALL_UPLOAD";
export const GoToStorageClick = "GO_TO_STORAGE_CLICK";
export const UploadBatchComplete = "UPLOAD_BATCH_COMPLETE";

// CALENDLY EVENT
export const enterpriseCalendly = "ENTERPRISE_CALENDLY";

// CARD SETUP SUCCESS
export const CARD_SETUP_SUCCESS = "CARD_SETUP_SUCCESS";
// UTMREFERRAL EVENT
export const UTM_REFERRAL_EVENT = "UTM_REFERRAL_EVENT";
// CARD SETUP SUCCESS
export const DASHBOARD_FIRST_VISIT = "DASHBOARD_FIRST_VISIT";

// Bulk transformation events
export const NEW_PLAYGROUND_VISIT = "NEW_PLAYGROUND_VISIT";
export const TRANSFORMATION_CLICK_START = "TRANSFORMATION_CLICK_START";
export const TRANSFORMATION_COMPLETED = "TRANSFORMATION_COMPLETED";
export const IMAGE_UPLOAD_ACTION = "IMAGE_UPLOAD_ACTION";
export const IMAGE_UPLOAD_COMPLETE = "IMAGE_UPLOAD_COMPLETE";
export const IMAGE_SELECT_CLEAR_ALL = "IMAGE_SELECT_CLEAR_ALL";
export const OLD_DASHBOARD_LANDING = "OLD_DASHBOARD_LANDING";
export const DOWNLOAD_ALL = "DOWNLOAD_ALL";
export const BUY_NOW_BUTTON = "BUY_NOW_BUTTON";
export const POPUP_CLICK_SUBSCRIPTION_PLAN = "POPUP_CLICK_SUBSCRIPTION_PLAN";
export const POPUP_CLICK_PAY_AS_YOU_GO = "POPUP_CLICK_PAY_AS_YOU_GO";
export const CHECKOUT_PAGE_CLICK = "CHECKOUT_PAGE_CLICK";
export const ADD_IMAGES_START = "ADD_IMAGES_START";

export const CHANGE_PLAYGROUND = "CHANGE_PLAYGROUND";
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const IMAGE_UPLOADED = "IMAGE_UPLOADED";
export const IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED";
export const ADD_IMAGES_CTA_CLICK = "ADD_IMAGES_CTA_CLICK";
export const IMAGES_ADDED = "IMAGES_ADDED";
export const TRANSFORMATION_START = "TRANSFORMATION_START";
export const DOWNLOAD_SINGLE = "DOWNLOAD_SINGLE";
export const CLEARED_BATCH = "CLEARED_BATCH";
export const BUY_CREDITS = "BUY_CREDITS";
export const OUT_OF_CREDITS_POP_UP = "OUT_OF_CREDITS_POP_UP";
export const OUT_OF_CREDITS_POP_UP_CLICKED = "OUT_OF_CREDITS_POP_UP_CLICKED";
export const NEW_PLAYGROUND_TO_PAYMENTS_PAGE = "NEW_PLAYGROUND_TO_PAYMENTS_PAGE";
export const ADD_CARD_INTIATED = "ADD_CARD_INTIATED";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const BUY_BUTTON_CLICK = "BUY_BUTTON_CLICK";
export const NEW_PLAYGROUND_PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const ADD_CARD_FAILURE = "ADD_CARD_FAILURE";
export const NEW_PLAYGROUND_PAYMENT_FAILURE = "NEW_PLAYGROUND_PAYMENT_FAILURE";

// BILLING AND PAYMENTS EVENTS
export const CHANGE_PLAN_CLICK = "CHANGE_PLAN_CLICK";
export const PLAN_CLICK = "PLAN_CLICK";
export const CARD_ADDITION_SUCCESS = "CARD_ADDITION_SUCCESS";
export const CARD_ADDITION_FAILURE = "CARD_ADDITION_FAILURE";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";
export const ADDON_PLAN_CLICK = "ADDON_PLAN_CLICK";
export const BUY_ADDON_CLICK = "BUY_ADDON_CLICK";
export const CHECKOUT_INITIATED = "CHECKOUT_INITIATED";
export const CHECKOUT_CLOSED = "CHECKOUT_CLOSED";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";
export const PAYMENT_METHOD_SELECTED = "PAYMENT_METHOD_SELECTED";
export const PAYMENT_INITIATED = "PAYMENT_INITIATED";

// RESEND SIGN-UP MAIL EVENTS
export const ResendEmailSuccess = "RESEND_EMAIL_SUCCESS";
export const ResendEmailFailed = "RESEND_EMAIL_FAILED";
export const ResendEmailTriggered = "RESEND_EMAIL_TRIGGERED";

// Google One Tap Login Events
export const GOOGLE_ONE_TAP_SHOWN = "GOOGLE_ONE_TAP_SHOWN";
export const GOOGLE_ONE_TAP_NOT_DISPLAYED = "GOOGLE_ONE_TAP_NOT_DISPLAYED";
export const GOOGLE_ONE_TAP_DISMISSED = "GOOGLE_ONE_TAP_DISMISSED";
export const GOOGLE_ONE_TAP_SKIPPED = "GOOGLE_ONE_TAP_SKIPPED";
export const GOOGLE_ONE_TAP_SUCCESS = "GOOGLE_ONE_TAP_SUCCESS";
