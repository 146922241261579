import environment from "../../environment";
import { replacePlatformDomain } from "./common-utils";

const NEJI_MAIN_URL = replacePlatformDomain(environment.NEJI_MAIN_URL);
const NEJI_WEBHOOK_URL = replacePlatformDomain(environment.NEJI_WEBHOOK_URL);
const HIRUZEN_MAIN_URL = replacePlatformDomain(environment.HIRUZEN_MAIN_URL);
const JIRAIYA_PNL_URL = replacePlatformDomain(environment.JIRAIYA_PNL_URL);
const RUPIKA_PNL_URL = replacePlatformDomain(environment.RUPIKA_PNL_URL);
const BORUTO_PUBLIC_URL = replacePlatformDomain(environment.BORUTO_PUBLIC_URL);
const HIDAN_PNL_URL = replacePlatformDomain(environment.HIDAN_PNL_URL);

const URLS = {
    // NEJI URLs
    deleteAccount: `${NEJI_MAIN_URL}/v1.0/profile`,
    registerUser: `${NEJI_MAIN_URL}/v1.0/authentication/register`,
    loginUser: `${NEJI_MAIN_URL}/v1.0/authentication/login/password`,
    setCookieToHinata: `${NEJI_MAIN_URL}/v1.0/authentication/token`,
    forgotPassword: `${NEJI_MAIN_URL}/v1.0/authentication/forgot-password`,
    resetPassword: `${NEJI_MAIN_URL}/v1.0/authentication/set-password`,
    verifyEmail: `${NEJI_MAIN_URL}/v1.0/authentication/register/complete`,
    googleLogin: `${NEJI_MAIN_URL}/v1.0/authentication/login/google`,
    logoutUser: `${NEJI_MAIN_URL}/v1.0/authentication/logout`,
    currentUserSession: `${NEJI_MAIN_URL}/v1.0/session`,
    allUserSessions: `${NEJI_MAIN_URL}/v1.0/session/list`,
    profileDetails: `${NEJI_MAIN_URL}/v1.0/profile/detail`,
    consent: `${NEJI_MAIN_URL}/v1.0/consent`,
    updateEmailInit: `${NEJI_MAIN_URL}/v1.0/profile/update-email-init`,
    updateEmailComplete: `${NEJI_MAIN_URL}/v1.0/profile/update-email-complete`,
    updatePassword: `${NEJI_MAIN_URL}/v1.0/profile/update-password`,
    searchUsers: `${NEJI_MAIN_URL}/v1.0/org/:orgId/search?email=:email`,
    googleOAuthLogin: `${NEJI_WEBHOOK_URL}/v1.0/authentication/google`,
    appleOAuthLogin: `${NEJI_WEBHOOK_URL}/v1.0/authentication/apple`,
    updateMeta: `${NEJI_MAIN_URL}/v1.0/profile/meta`,
    googleOneTapLogin: `${NEJI_WEBHOOK_URL}/v1.0/authentication/google/one-tap`,
    // HIRUZEN URLs
    createOrganization: `${HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationDetails: `${HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationDetailsById: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/detail`,
    updateOrganizationDetailsById: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/detail`,
    getOrganizationPreference: `${HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationsForUser: `${HIRUZEN_MAIN_URL}/v1.0/all`,
    createApp: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps`,
    getApps: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps`,
    selectApps: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId/select`,
    getAppDetails: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    updateApp: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    deleteApp: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    createTeamMember: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/`,
    getTeam: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/?userId=:userId&page=:page&pageSize=:pageSize`,
    getTeamMember: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    updateTeamMember: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    deleteTeamMember: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    getRoles: `${HIRUZEN_MAIN_URL}/v1.0/role`,
    getDomains: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/?searchQuery=:searchQuery&page=:page&pageSize=:pageSize`,
    getDomainDetails: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,
    createDomain: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains`,
    deleteDomain: `${HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,

    // CHOJI URLs
    search: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/search`,
    explore: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore`,
    exploreFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore/folders`,
    directUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/direct`,
    signedUrlUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/signed-url`,
    urlsUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/urls`,
    createFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    filesUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files`,
    transformationsUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files/transformations`,
    foldersUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    ancestors: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders/:folderId/ancestors`,
    download: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/proxy/download`,
    downloadOpen: `${environment.CHOJI_PNL_URL}/v1.0/proxy/download`,
    plugins: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/plugins`,
    playgroundDefault: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/default`,
    credentials: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/credentials`,
    presets: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/presets`,
    updateFile: `${environment.CHOJI_PNL_URL}/v1.0/files/:fileId`,

    // GARRA URLS
    webhooks: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs`,
    webhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/:webhookId`,
    testWebhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/test`,
    webhookEvents: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/events`,
    webhookLogs: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/logs`,

    // ITACHI URLS
    insights: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/insights`,

    // JIRAIYA URLS
    zones: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones`,
    zone: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/:zoneId`,
    zoneQualityOptions: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/quality-options`,
    datasourceSchemas: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas`,
    datasourceSchema: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas/:datasourceType`,
    datasources: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources`,
    datasource: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId`,
    datasourceAssets: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId/assets`,
    dropboxDatasourceOAuthRedirectUri: `${environment.JIRAIYA_WEBHOOK_URL}/v1.0/datasources/dropbox/auth`,
    purgeCdns: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge`,
    purgeCdnsAll: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge/all`,
    jobs: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs`,
    activeJobCount: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/state/active`,
    bulkDownload: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/download/bulk`,
    bulkValidate: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/validate/bulk`,
    transformationDetail: `${environment.JIRAIYA_CDN_DOMAIN}/context`,
    signedURLs: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/signed-urls`,
    bharatdiffusionGenerate: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/predictions/bharatDiffusion/generate`,
    bharatdiffusionGenerationStatus: `${JIRAIYA_PNL_URL}/v1.0/org/:orgId/predictions/:generationId`,

    // ITACHI URLS
    transformationList: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/transformations/list`,

    // RUPIKA URLS:
    subscriptions: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions`,
    activeSubscription: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/active-subscription`,
    cancelSubscription: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/cancel-subscription`,
    upcomingSubscription: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/upcoming-subscription`,
    plan: `${RUPIKA_PNL_URL}/v1.0/plans/:id`,
    plans: `${RUPIKA_PNL_URL}/v1.0/plans`,
    addOn: `${RUPIKA_PNL_URL}/v1.0/addons/:id`,
    addOns: `${RUPIKA_PNL_URL}/v1.0/addons`,
    cards: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/cards`,
    updateCards: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/:paymentMethodId`,
    initCard: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/init`,
    invoices: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices`,
    pgInvoice: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices/pgId/:pgId`,
    pgInvoiceOpen: `${RUPIKA_PNL_URL}/v1.0/invoices/pgId/:pgId`,
    subscriptionUsage: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/subscription`,
    addonsUsage: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/addons`,
    totalUsage: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/usage`,
    refunds: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/refunds`,
    payments: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/payments`,
    customerAddOns: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/customerAddons`,
    billingAddress: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/customers/billingAddress`,
    currency: `${RUPIKA_PNL_URL}/v1.0/currency`,
    getChangeActionType: `${RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/change-action-type`,

    // HIDAN URLS
    // createGlamarSku: `${HIDAN_PNL_URL}/v1.0/org/:orgId/makeup/sku`,
    deleteBySku: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    disableBySku: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    getPaginatedSkus: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus`,
    createGlamarSku: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus`,
    updateGlamarSku: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    getSkuById: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    bulkDeleteSkus: `${HIDAN_PNL_URL}/v1.0/org/:orgId/skus/delete`,
    modelConfig: `${HIDAN_PNL_URL}/v1.0/org/:orgId/models`,
    toggleModelStatus: `${HIDAN_PNL_URL}/v1.0/org/:orgId/models/:modelId`,
    deleteModel: `${HIDAN_PNL_URL}/v1.0/org/:orgId/models/:modelId`,
    createGeneration: `${HIDAN_PNL_URL}/v1.0/org/:orgId/userGenerations`,
    getGenerationHistory: `${HIDAN_PNL_URL}/v1.0/org/:orgId/userGenerations`,
    getGenerationById: `${HIDAN_PNL_URL}/v1.0/org/:orgId/userGenerations/:id`,
    updateGenerationById: `${HIDAN_PNL_URL}/v1.0/org/:orgId/userGenerations/:id`,
    deleteGenerationById: `${HIDAN_PNL_URL}/v1.0/org/:orgId/userGenerations/:id`,

    // SHIRAYUKI URLS
    auditLogs: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs`,
    auditLog: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs/:logId`,

    // CMS URLs
    exploreImages: `${BORUTO_PUBLIC_URL}/api/bd-explore-images?populate=images&pagination[start]=:startIndex&pagination[limit]=:stopIndex`,
    exploreImage: `${BORUTO_PUBLIC_URL}/api/bd-explore-images/details/:id`,
};

export default URLS;
