import PopupWrapper from "@components/Popup/PopupWrapper";
import React from "react";
import Button from "../Button";
import environment from "@src/environment";

const CreditLimitExceededPopup = ({ isOpen, setIsOpen }) => {
    return (
        <PopupWrapper isOpen={isOpen} setIsOpen={setIsOpen} showCloseButton={true}>
            <div className="px-7.5 py-6.5 flex flex-col gap-4 items-center">
                <h2 className="text-white text-center text-2xl font-semibold">
                    You have exhausted your credits!
                </h2>
                <p className="text-body-2 text-center text-lg font-normal">
                    Upgrade your plan to continue generating more images using Bharat Diffusion
                </p>
                <Button
                    className={"w-fit"}
                    as={"a"}
                    href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}/pricing`}
                >
                    Upgrade Plan
                </Button>
            </div>
        </PopupWrapper>
    );
};

export default CreditLimitExceededPopup;
