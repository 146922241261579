import executor from "@common/utils/executor";
import AuthGuard from "@src/guards/auth.guard";
import OrganizationGuard from "./guards/organization.guard";
import { lazy } from "react";

const Dashboard = lazy(() => import("@pages/Dashboard"));
const UserGenerationDetails = lazy(() => import("@pages/UserGenerationDetails"));
const ExploreDetails = lazy(() => import("@pages/ExploreGenerationDetails"));
const Auth = lazy(() => import("@pages/Auth"));
const Organization = lazy(() => import("@pages/Organization"));

const routes = [
    {
        path: "/auth",
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/organization/:orgId/generations/:id",
        component: UserGenerationDetails,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId/explore/:id",
        component: ExploreDetails,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    // {
    //     path: "/",
    //     component: Dashboard,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },

    // {
    //     path: "/setup-org",
    //     component: SetupOrg,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },
    // {
    //     path: "/profile",
    //     component: Profile,
    //     guard: () => {
    //         return executor([AuthGuard, ConsentGuard]);
    //     },
    // },
    // {
    //     path: "/consent-approval",
    //     component: ConsentConfirmation,
    //     guard: () => {
    //         return executor([AuthGuard]);
    //     },
    // },
    // {
    //     path: "/checkout",
    //     component: OpenCheckout,
    //     guard: () => {
    //         return executor([AuthGuard]);
    //     },
    // },
    // {
    //     path: "/organization/:orgId/billing",
    //     component: Billing,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },
    // {
    //     path: "/organization/:orgId/settings/billing/checkout/finish",
    //     component: CheckoutFinished,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },
    // {
    //     path: "/organization/:orgId/settings/billing/checkout",
    //     component: Checkout,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard, ConsentGuard]);
    //     },
    // },
    // {
    //     path: "/organization/:orgId/preview/:fileId*",
    //     component: PreviewImage,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },
    // {
    //     path: "/editor/:id?",
    //     component: Editor,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    // {
    //     path: "/email-update",
    //     component: EmailUpdateConfirmation,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    // {
    //     path: "/connect/openai/auth",
    //     component: OpenAiAuth,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    // {
    //     path: "/redirect",
    //     component: Redirection,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    {
        path: "*",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
];

export default routes;
